import React, {Component} from 'react';
import {Row, Col}         from 'antd';
import {connect}          from 'react-redux';
import Banner             from './componets/Banner';
import OCard              from './componets/OCard';
// import MessageItem        from './componets/MessageItem';
// import sourceData         from './data';
import {userInfo}         from '../../redux/action';
import home_ima1    from '../../resource/images/home_ima1.png';
import home_ima2    from '../../resource/images/home_ima1-1.png';
import home_ima3    from '../../resource/images/home_ima3.png';
import home_ima7    from '../../resource/images/home_ima7.png';
import home_ima8    from '../../resource/images/home_ima8.png';
import home_ima9    from '../../resource/images/home_ima9.png';
import home_ima10    from '../../resource/images/home_ima10.png';
import home_banner1 from '../../resource/images/home_banner.jpg';
import './index.less';
import { host } from '../../config';


class Home extends Component {
    componentDidMount() {
        const {dispatch} = this.props;

        if (this.props.userToken) {
            dispatch(userInfo(this.props.userToken));
        }
    }

    render() {
        // const {messages, adBgUrl} = sourceData;

        return (
            <div className="home-page">
                <section>
                    <Banner imgUrls={[home_banner1]}/>
                </section>

                <section className="list-card-wrap container">
                    <Row gutter={4}>
                        <Col
                            xs={24}
                            sm={12}
                            md={8}
                            span={8}
                        >
                            <OCard
                                path="/registration/list"
                                text="REGISTRATION"
                                imgUrl={home_ima3}
                            />
                        </Col>
                        <Col
                            xs={24}
                            sm={12}
                            md={8}
                            span={8}
                        >
                            <OCard
                                path="/poster/upload"
                                text="Poster upload"
                                imgUrl={home_ima9}
                            />
                        </Col>
                        <Col
                            xs={24}
                            sm={12}
                            md={8}
                            span={8}
                        >
                            <OCard
                                path="/oral/upload"
                                text="Oral upload"
                                imgUrl={home_ima10}
                            />
                        </Col>
                        <Col
                            xs={24}
                            sm={12}
                            md={8}
                            span={8}
                        >
                            <OCard
                                path="https://www.iacr2024.com/static/media/program-at-a-glance.jpg"
                                text="Program at a glance"
                                imgUrl={home_ima1}
                            />
                        </Col>
                        <Col
                            xs={24}
                            sm={12}
                            md={8}
                            span={8}
                        >
                            <OCard
                                path={`${host}/pdf/transportation.pdf`}
                                text="Transportation"
                                imgUrl={home_ima7}
                            />
                        </Col>
                        <Col
                            xs={24}
                            sm={12}
                            md={8}
                            span={8}
                        >
                            <OCard
                                path={`${host}/pdf/${encodeURIComponent('Scenic spot')}.pdf`}
                                text="Scenic spot"
                                imgUrl={home_ima8}
                            />
                        </Col>
                        <Col
                            xs={24}
                            sm={12}
                            md={8}
                            span={8}
                        >
                            <OCard
                                path="https://www.iacr2024.com/images/20240814180957.png"
                                text="国内参会通道"
                                imgUrl={home_ima2}
                            />
                        </Col>

                    </Row>
                </section>

                <section className="list-wrap ">
                    <div className="content container">
                        <h3 className="title_line">Instructions</h3>
                        <div>
                            <div>Create the delegate account with a login and password.</div>
                            <div>Keep the login (email address as this will be the means to view / make changes and see the status of the abstract).</div>
                            <div>If you have already submitted an abstract and wish to view or amend your paper, please login using the email address and password of the delegate account.</div>

                            <div style={{marginTop: 20}}>Please note abstracts can be submitted until <b>16 May 2024 24:00（China Standard Time，CST）</b></div>

                            <div style={{marginTop: 20}}>
                                <p style={{margin: 0}}>Registration for 2024 lACR Scientific Conference & Chinese Cancer Registry Annual Conference opens on <b>18th
                                    July 2024</b></p>
                            </div>

                            <div style={{marginTop: 20}}>
                                <p style={{margin: 0}}>Link to IACR's fellowship application:</p>
                                <p style={{margin: 0}}>
                                    <a
                                        href="https://datacollect.iarc.fr/redcap/surveys/?s=K9JWMR8PR88PHDT4"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >https://datacollect.iarc.fr/redcap/surveys/?s=K9JWMR8PR88PHDT4</a>
                                </p>
                            </div>

                            <div style={{marginTop: 20}}>
                                <p style={{margin: 0}}>Fellowship application guidelines:</p>
                                <p style={{margin: 0}}>
                                    <a
                                        href="http://www.iacr.com.fr/images/doc/Fellowships/CP_Fellowship_guidelines_2012.doc"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >http://www.iacr.com.fr/images/doc/Fellowships/CP_Fellowship_guidelines_2012.doc</a>
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        userInfo : state.userInfo,
        userToken: state.userToken,
    };
};

export default connect(mapStateToProps)(Home);
