import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Modal, Spin, Upload } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import {baseUrl} from '../../../config'
import './index.less';
import axios from '../../../axios/api.request';
import oralPresentationGuidelines from '../../../resource/images/oral-presentation-guidelines.png';

class OralUpload extends React.Component {
    state = {
        loading: true,
        fileList: [],
    };

    componentDidMount () {
        const {history, userInfo} = this.props;

        if (!userInfo || !userInfo.id) {
            history.push('/login');

            return;
        }

        this.getUploadedFiles();
    }

    getUploadedFiles() {
        const {userInfo} = this.props;

        this.setState({
            loading: true,
        });

        axios.request({
            url: '/oral/upload',
            method: 'get',
            params: {
                api_token: userInfo.api_token,
            }
        }).then(rs => {
            this.setState({
                fileList: rs.data.data || [],
            });
        }).catch(err => {
            Modal.error({
                title: 'Error',
                content: err.message || 'The list content query failed.',
            });
        }).finally(() => {
            this.setState({
                loading: false,
            });
        });
    }

    render () {
        const {userInfo} = this.props;
        const {loading, fileList} = this.state;

        return (
            <div className="container poster-upload-page oral-upload-page">
                <div className="title">
                    <h1>ORAL UPLOAD</h1>
                </div>

                <div className="image">
                    <img src={oralPresentationGuidelines} alt="Oral Presentation Guidelines"/>
                </div>

                <div className="upload">
                    {loading ? (
                        <div className="loading">
                            <Spin size="large" tip="Loading..."/>
                        </div>
                    ) : (
                        <Upload.Dragger
                            data={{
                                api_token: userInfo.api_token,
                            }}
                            action={`${baseUrl}/oral/upload`}
                            accept=".ppt,.pptx"
                            multiple={false}
                            maxCount={5}
                            defaultFileList={fileList}
                            onRemove={file => {
                                if (!file.response || !file.response.id) {
                                    return true;
                                }

                                return new Promise((resolve, reject) => {
                                    axios.request({
                                        url: `/oral/upload/${file.response.id}`,
                                        data: {
                                            api_token: userInfo.api_token,
                                        },
                                        method: 'delete',
                                    }).then(rs => {
                                        if (rs.data.code === 200) {
                                            resolve();
                                            return;
                                        }

                                        throw new Error(rs.data.message || 'The list content query failed.');
                                    }).catch(err => {
                                        Modal.error({
                                            title: 'Error',
                                            content: err.message || 'The list content query failed.',
                                        });

                                        reject();
                                    });
                                });
                            }}
                        >
                            <p className="ant-upload-drag-icon">
                                <InboxOutlined/>
                            </p>
                            <p className="ant-upload-text">Click or drag file to this area to upload</p>
                            <p className="ant-upload-hint">
                                Support for a single or bulk upload. Only PPT or PPTX files are accepted, and the size
                                of each file must not exceed 20MB.
                            </p>
                        </Upload.Dragger>
                    )}
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        userInfo: state.userInfo,
        userToken: state.userToken,
    };
};

export default connect(mapStateToProps)(withRouter(OralUpload));
